<template>
  <div v-if="!token" class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
      id="kt_login"
    >
      <div class="side_image">
        <img src="/media/side_image.png" />
      </div>
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="logo text-center mb_85 pt-lg-0 pt-5">
                <img
                  v-if="base_URL.includes('fehm')"
                  style="width: 86%"
                  src="/media/fehm_logo.png"
                />
                <img
                  v-else-if="base_URL.includes('mcit')"
                  alt="Logo"
                  src="/media/logo.png"
                />
                <img v-else alt="Logo" src="/media/Logos/manash.png" />
              </div>
              <div v-if="error_mesage == 'unauthorized'" class="error_mesage">
                <p>
                  بريد إلكترونى او كلمة مرور غير صالحين برجاء التأكد من بيانات
                  الدخول
                </p>
              </div>
              <div
                v-if="sucss_mesage == 'paswordChanged'"
                class="sucsess_mesage"
              >
                <p>
                  لقد قمت بتغيير كلمة المرور الخاصه بك برجاء تسجيل الدخول
                  بإستخدام كلمة المرور الجديدة
                </p>
              </div>
              <div class="form-group">
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <i class="far fa-envelope"></i>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="email"
                    type="email"
                    placeholder="البريد الإلكترونى"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="form-group">
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <b-icon icon="lock"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="password"
                    type="password"
                    placeholder="كلمة المرور"
                  ></b-form-input>
                </b-input-group>
              </div>

              <div class="form-group">
                <v-btn
                  :loading="isLoading ? true : false"
                  ref="kt_login_signin_submit"
                  class="btn btn_brimary font-weight-bolder font-size-h6 px-15 py-4 my-3"
                  @click="onSubmit()"
                >
                  تسجيل الدخول
                </v-btn>
              </div>
              <div v-if="base_URL.includes('login_mt')"  class="or_class">
              <div class="line"></div>
              <div class="or">
               أو
              </div>
             
                <div class="line"></div>
              </div>
              <div v-if="base_URL.includes('login_mt')" class="form-group text-center">
                <v-btn
                @click="$router.push(`/login_gust/${$route.params.meeting_id}`)"
                class="guest_btn guest_btn-border"
               >
                 قم بالتسجيل كضيف
                </v-btn>
              </div>
            </form>
          </div>
        </div>

        <!--begin::Content footer-->
        <div
          class="login_footer d-flex text-center justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          جميع الحقوق محفوظه لدى وزارة الاتصالات وتقنية المعلومات 2021
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center flex-row-auto"
        :style="{ backgroundImage: `url(/media/Path1.svg` }"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat position-relative bgi-size-cover bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(/media/tower.png)` }"
        >
          <div class="slogin_container">
            <h3>
              تمكين المملكة العربية السعودية , اقتصاديا<br />
              ومجتمعا ووطنا, من استثمار الفرص التى يوفرها<br />
              عنصر الرقمنه والوصول بالمملكة لطليعة الدول<br />
              المبتكرة
            </h3>
          </div>
          <div class="green_path">
            <img src="/media/Path2.svg" />
          </div>
        </div>
      </div>
      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      isLoading: false,
      error_mesage: "",
      email: "",
      password: "",
      state: "signin",
      sucss_mesage: "",
      token: null,
      base_URL: "",
      lastUrl: "",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
  },
  mounted() {},

  methods: {
    onSubmit() {
      this.isLoading = true;
      const email = this.email;
      const password = this.password;
      this.$store.dispatch(LOGOUT);
      this.$store.dispatch(LOGIN, { email, password }).then((res) => {
        if (res.message == "success") {
          this.isLoading = false;
          var passwordChanged = window.localStorage.getItem("paswordChanged");
          if (passwordChanged) {
            window.localStorage.removeItem("paswordChanged");
          }
          if (res.data.email_verified_at && res.data.mobile_verified_at) {
            // const userData = JSON.parse(localStorage.getItem('User_data'));
            if (this.$route.params.groupslug) {
              window.location.href = `#/join/${this.$route.params.groupslug}`;
            } else {
              if (this.lastUrl) {
                window.location.href = `/${this.lastUrl}`;
              } else {
                window.location.href = `/`;
              }
            }
          } else if (!res.data.mobile_verified_at) {
            this.$store.dispatch(LOGOUT);
            this.$router.push(
              `/moblie-validtion/${res.data.mobile}/${res.data.email}`
            );
          } else {
            this.$store.dispatch(LOGOUT);
            this.$router.push(`/email_validtion/${res.data.email}`);
          }
        } else {
          this.isLoading = false;
          this.error_mesage = "unauthorized";
        }
      });
    },
  },
  created() {
    this.lastUrl = sessionStorage.getItem("lastUrl");
    this.token = localStorage.getItem("id_token");
    this.base_URL = window.location.href;
    if (this.token) {
      window.location.href = "/";
    } else {
      var passwordChanged = window.localStorage.getItem("paswordChanged");
      if (passwordChanged) {
        this.sucss_mesage = "paswordChanged";
      }
    }
  },
};
</script>
<style scoped lang="scss">
.form-control {
  background: transparent !important;
}

</style>
